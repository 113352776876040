import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/useAuthContext";
import "./header.scss";
import mainLogo from "../../assets/icons/favicon.png";
import micoWorkLogo from "../../assets/icons/micoworksimg.png";
import translateIcon from "../../assets/icons/header_icon.svg";
import downArrow from "../../assets/icons/drop_blue.svg";
import user_icon from "../../assets/icons/user_icon.svg";
import {
  SiteLoader,
  UserNameLoader,
} from "../../components/Loaders/DropDownLoader";
import { Skeleton } from "@mui/material";
import { useSearchContext } from "../../context/useSearchContext";
import tick from "../../assets/icons/tick.svg";
import { InputSwitch } from "primereact/inputswitch";

const Header = () => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const {
    permissions,
    removeSession,
    saveSelectedSite,
    selectedSite,
    initialLoading,
    isDebug,
    setIsDebug
  } = useAuthContext();
  const { setFormValue, setShowResults } = useSearchContext();
  const [filteredSites, setFilteredSites] = useState(permissions?.access_sites);
  const inputRef = useRef(null);
  const languageRef = useRef(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const currentLanguage = i18n.language;
  const toggleModal = () => {
    setIsLanguageModalOpen((prevValue) => !prevValue);
  };

  useEffect(() => {
    setFilteredSites(permissions?.access_sites);
    return () => { };
  }, [permissions]);

  const onSiteChange = (index, site = "") => {
    saveSelectedSite(index, site);
    setIsDropDownOpen(false);
    setFilteredSites(permissions?.access_sites);
  };

  const handleLanguageSelection = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("selectedLanguage", language);
    setIsLanguageModalOpen(false);
  };

  const handleSiteChange = (e) => {
    const value = e.target.value;
    const filtered = permissions?.access_sites.filter((site) =>
      site.site_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSites(filtered);
  };

  const handleLogout = () => {
    localStorage.clear();
    removeSession();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsDropDownOpen(false);
      }
    };

    if(isDropDownOpen){
      setFilteredSites(permissions?.access_sites);
    }

    const handleClickOutsideLang = (event) => {
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        setIsLanguageModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutsideLang);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleClickOutsideLang);
    };
  }, [isDropDownOpen]);

  const clearFormValue = () => {
    setShowResults(false);
    setFormValue({
      input_text: "",
      page: 1,
      ctr: 0,
      tags: [],
      model_filters: [],
      search_by: "both",
      is_edited: "all"
    });
  };

  return (
    <div className="d-flex justify-content-between align-items-center py-3 gap-2 gap-sm-4 px-2 px-sm-2 px-lg-5 border-bottom sticky-top bg-white font-sora header-wrapper">
      <div className="container-fluid p-0">
        <div className="row">
          {initialLoading ? (
            <SiteLoader />
          ) : (
            selectedSite?.site_name && (
              <>
                <div className="col-md-4 col-12 ">
                  <div
                    className="w-100 position-relative max-w-400 "
                    ref={inputRef}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      className="border rounded-pill d-flex gap-2 border-light-purple align-items-center justify-content-between  min-w-127 p-1 pe-3 pe-md-3"
                      onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                    >
                      <img
                        src={mainLogo}
                        alt="header_logo"
                        className="img-fluid input-icon-width"
                      />
                      {isDropDownOpen ? (
                        <input
                          type="text"
                          className="remove_outline w-100"
                          onChange={handleSiteChange}
                          // ref={inputRef}
                          autoFocus
                        ></input>
                      ) : (
                        <span className="text-500 fs-14 w-100">
                          {selectedSite?.site_name}
                        </span>
                      )}
                      <img
                        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                        src={downArrow}
                        className={`bi bi-chevron-down`}
                        style={{
                          cursor: "pointer",
                          transform: isDropDownOpen
                            ? "rotate(180deg)"
                            : "rotate(0)",
                        }}
                        alt="icon"
                      />
                    </div>

                    {isDropDownOpen && (
                      <>
                        <ul className="position-absolute list-unstyled sitemenu w-100">
                          {permissions && (
                            <li onClick={() => onSiteChange(-1)}>
                              <div
                                className="sitemenu-item text-center fs-14"
                                href="#"
                              >
                                All
                              </div>
                            </li>
                          )}
                          {filteredSites &&
                            filteredSites?.map((site, index) => (
                              <li
                                key={index}
                                onClick={() => onSiteChange(index, site)}
                              >
                                <div
                                  className="sitemenu-item text-center fs-14"
                                  href="#"
                                >
                                  {site.site_name}
                                </div>
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </>
            )
          )}

          <div className="col-md-4 col-12 mt-md-0 mt-2 " style={{alignSelf:"center"}}>
            <div className="w-100 text-lg-center d-flex justify-content-center align-items-center logo-align">
              <Link
                to={"/search"}
                className="text-decoration-none d-flex align-items-center"
                onClick={clearFormValue}
              >
                <img
                  src={micoWorkLogo}
                  alt="main logo"
                  className="img-fluid mico-logo-width"
                />
              </Link>
            </div>
          </div>



          <div className="col-md-4 col-12 mt-md-0 mt-2" style={{alignSelf:"center"}}>
            <div className="position-relative w-100 ">
              <div className="d-flex  flex-container align-items-center gap-lg-2 gap-xxl-4 gap-1">

                {permissions?.debug_mode ? (
                  <div className="d-flex justify-content-center align-items-center gap-1 fs-14"
                  >
                    <span>{t("allowDebug")}</span>
                    <InputSwitch
                      checked={isDebug}
                      onChange={(e) => {
                        setIsDebug(e.value)
                      }}
                    />
                  </div>
                ) : <></>}
                {initialLoading ? (
                  <UserNameLoader />
                ) : (
                  permissions?.user_name && (
                    <div className="dropdown ">
                      <button
                        className="border-purple rounded-pill fs-14 bg-transparent py-sm-2 py-1 px-sm-3 dropdown-toggle min-w-170"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={user_icon}
                          alt="header_logo"
                          className="img-fluid me-1"
                        />
                        {permissions?.user_name}
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li
                          className="dropdown-menu-item bg-light rounded"
                          onClick={handleLogout}
                        >
                          <div className="dropdown-item text-center" href="#">
                            {t("logout")}
                          </div>
                        </li>
                        <li className="dropdown-menu-item bg-light rounded">
                          <Link
                            to={"/settings"}
                            className="dropdown-item text-center border-bottom-0 pb-0"
                          >
                            {t("settings")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )
                )}

                {initialLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                  />
                ) : (
                  <button
                    className="rounded-circle border-0 bg-transparent"
                    onClick={toggleModal}
                    ref={languageRef}
                  >
                    <img
                      src={translateIcon}
                      className=""
                      alt="translate"
                      width={55}
                    />
                    {isLanguageModalOpen && (
                      <div
                        className="position-absolute "
                        style={{ top: "50px", right: "0px" }}
                        onClick={toggleModal}
                      >
                        <div className="">
                          <ul className="Language-down">
                            <li
                              className="Language-down-item px-4"
                              onClick={() => handleLanguageSelection("en")}
                            >
                              {currentLanguage === "en" && (
                                <img
                                  src={tick}
                                  alt="tick"
                                  className="img-fulid"
                                  height={20}
                                  width={20}
                                />
                              )}
                              English
                            </li>
                            <li
                              className="Language-down-item px-4"
                              onClick={() => handleLanguageSelection("ja")}
                            >
                              {currentLanguage === "ja" && (
                                <img
                                  src={tick}
                                  alt="tick"
                                  className="img-fulid"
                                  height={20}
                                  width={20}
                                />
                              )}
                              日本語
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </button>
                )}



              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
