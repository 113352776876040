import React from "react";
import micovistion from "../../assets/icons/micovisionai.svg";

const MicoBackground = () => {
  return (
    <div className="searchHeaderBg" style={{ maxHeight: "260px" }}>
      <div className="d-flex ">
        <div style={{ maxWidth: "200px", marginTop: "-38px" }}>
          <img
            src={micovistion}
            className="w-100 marign-left-28"
            alt="micologo"
          />
        </div>
        <div className="marign-left-28">
          <p className="mb-0 text-gray fw-bold">
            New Creatives in Minutes with
          </p>
          <h4 className="mb-0 text-gray fw-bold fs-35">MICO VISION AI</h4>
        </div>
      </div>
    </div>
  );
};

export default MicoBackground;
