import { I18nextProvider } from "react-i18next";
import i18n from "./assets/i18n/i18n";
import { AppRouter } from "./routes/router";
import { AuthProvider } from "./context/useAuthContext";
import { ToastContainer } from "react-toastify";

import "./assets/scss/home.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "react-toastify/dist/ReactToastify.css";
import { WebSocketProvider } from "./context/useSocketContext";
import { UploadProvider } from "./context/useUploadContext";
import { EditProvider } from "./context/useEditContext";
import { TranslationProvider } from "./context/useTranslationContext";
import { FilterProvider } from "./context/useFilterContext";
import { SearchProvider } from "./context/useSearchContext";

function App() {
  return (
    <>
      <WebSocketProvider>
        <UploadProvider>
          <AuthProvider>
            <EditProvider>
              <FilterProvider>
                <TranslationProvider>
                  <SearchProvider>
                    <I18nextProvider i18n={i18n}>
                      <AppRouter />
                    </I18nextProvider>
                  </SearchProvider>
                </TranslationProvider>
              </FilterProvider>
            </EditProvider>
          </AuthProvider>
        </UploadProvider>
      </WebSocketProvider>
      <ToastContainer />
    </>
  );
}

export default App;
