import { Skeleton } from "@mui/material";
import React from "react";
import "../../views/Search/search.scss";

const DashCardLoader = () => {
  return (
    <div className="min-w-300 dashboard-card">
      <span
        className="dashboard-title my-3"
        height="100%"
        width="100%"
        style={{ width: "100%" }}
      >
        <Skeleton
          animation="wave"
          variant="text"
          height="100%"
          width="100%"
          style={{ width: "100%" }}
        />
      </span>
      <Skeleton variant="rounded" animation="wave" height="100%" width="100%" />
      <Skeleton
        animation="wave"
        height={50}
        variant="rounded"
        className={`rounded-pill border-0 w-100 p-2 my-3 btn`}
      />
    </div>
  );
};

export { DashCardLoader };
