import React, { useState, useEffect } from "react";
import downArrow from "../assets/icons/arrow-down.svg";
import { useTranslation } from "react-i18next";
import { handleCheckboxChange } from "./search";

function CategoryOptions({ tag, formValue, setFormValue }) {

  const [showAll, setShowAll] = useState(false);
  const [disableOtherOptions, setDisableOtherOptions] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState({});
  const { t } = useTranslation();
  const [visibleOptionsLength, setVisibleOptionsLength] = useState(4);

  useEffect(() => {
    const initialCheckedOptions = {};
    const options = Object.entries(tag.category_options);

    if (formValue[tag.category_key]) {
      formValue[tag.category_key].forEach((value) => {
        const option = options.find(([_, v]) => v == value);
        if (option) {
          initialCheckedOptions[option[0]] = true;
        }
      });
    }
    setCheckedOptions(initialCheckedOptions);
  }, [formValue, tag.category_key, tag.category_options]);

  useEffect(() => {
    const checkedOptionsLength = Object.keys(checkedOptions).length;
    if (checkedOptionsLength > 4) {
      setVisibleOptionsLength(checkedOptionsLength);
    } else {
      setVisibleOptionsLength(4);
    }
  }, [checkedOptions]);

  const options = Object.entries(tag.category_options);

  const sortedOptions = options.sort(([a], [b]) => {
    return (checkedOptions[b] ? 1 : 0) - (checkedOptions[a] ? 1 : 0);
  });

  const limitedOptions = sortedOptions.slice(0, visibleOptionsLength);
  const remainingOptionsCount = (sortedOptions.length - visibleOptionsLength) > 0 ? (sortedOptions.length - visibleOptionsLength) : (sortedOptions.length - visibleOptionsLength);

  const toggleShowAll = () => setShowAll(!showAll);

  const handleChange = (e, value, tag, option) => {
    // if (value == -2) {
    //   onAllToggle(e)
    // } else {

    const isChecked = e.target.checked;
    setCheckedOptions((prev) => {
      const newCheckedOptions = { ...prev, [option]: isChecked };
      return newCheckedOptions;
    });

    handleCheckboxChange(e, value, tag, formValue, setFormValue, isChecked);
    // }
  };

  const onAllToggle = async (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const allCheckedOptions = {};
      options.forEach((value) => {
        allCheckedOptions[value[0]] = true;
      });
      setCheckedOptions(allCheckedOptions);
      handleCheckboxChange(e, -2, tag, formValue, setFormValue, isChecked);
      setDisableOtherOptions(true);
    } else {
      const allCheckedOptions = {};
      options.forEach((value) => {
        allCheckedOptions[value[0]] = false;
      });
      setCheckedOptions(allCheckedOptions);
      handleCheckboxChange(e, -2, tag, formValue, setFormValue, isChecked);
      // setDisableOtherOptions(false);
    }
  };


  return (
    <ul className="category-options-list">
      {(showAll ? sortedOptions : limitedOptions).map(
        ([option, value], optionIndex) => (
          <li key={optionIndex} className="p-1">

            <input
              type="checkbox"
              // disabled={(checkedOptions['All'] !== undefined && checkedOptions['All'] === true && value !== -2)}
              checked={!!checkedOptions[option]}
              id={`option_${option}_${optionIndex}_${tag?.category_key}`}
              name={tag?.category_key}
              onChange={(e) => handleChange(e, value, tag, option)}
            />
            <label
              htmlFor={`option_${option}_${optionIndex}_${tag?.category_key}`}
              style={{
                cursor: "pointer",
              }}
            >
              {t(option)}
            </label>
          </li>
        )
      )}
      {/* {sortedOptions?.length >= 2 &&
        (showAll || sortedOptions.length === visibleOptionsLength) && (
          <>
            <li key={-2} className="p-1">
              <input
                type="checkbox"
                id={`option_all_-2_${tag?.category_key}`}
                name={tag?.category_key}
                onChange={(e) => onAllToggle(e)}
              />
              <label
                htmlFor={`option_all_-2_${tag?.category_key}`}
                style={{
                  cursor: "pointer",
                }}
              >
                {t("All")}
              </label>
            </li>
          </>
        )} */}
      {remainingOptionsCount > 0 && !showAll && (
        <li
          className="show-more"
          onClick={toggleShowAll}
          style={{ cursor: "pointer" }}
        >
          <span>
            {t("showMore")}({remainingOptionsCount})
          </span>
          <img
            onClick={() => setShowAll(!showAll)}
            src={downArrow}
            className={`bi bi-chevron-down`}
            style={{
              cursor: "pointer",
              transform: showAll ? "rotate(180deg)" : "rotate(0)",
            }}
            alt="icon"
          />
        </li>
      )}
      {(showAll && !checkedOptions['All']) && (
        <li
          className="show-more"
          onClick={toggleShowAll}
          style={{ cursor: "pointer" }}
        >
          <span>{t("showLess")}</span>
          <img
            onClick={() => setShowAll(!showAll)}
            src={downArrow}
            className={`bi bi-chevron-down`}
            style={{
              cursor: "pointer",
              transform: showAll ? "rotate(180deg)" : "rotate(0)",
            }}
            alt="icon"
          />
        </li>
      )}
    </ul>
  );
}

export default CategoryOptions;
