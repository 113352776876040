import { Navigate, Route, Routes } from "react-router-dom";
import { useAuthContext } from "../context/useAuthContext";
import DefaultLayout from "../layouts/DefaultLayout";
import UserLayout from "../layouts/UserLayout";
import { protectedRoutes, publicRoutes } from "./index";
import { getAllSearch } from "../helpers/url";
import PageLoading from "../views/PageLoading";

export const AppRouter = () => {
  const { isAuthenticated, authInitialLoading } = useAuthContext();
  const { id } = getAllSearch();

  return (
    <Routes>
      {publicRoutes.map((route, idx) => (
        <Route
          key={route.path + idx}
          path={route.path}
          element={<DefaultLayout>{route.element}</DefaultLayout>}
        />
      ))}

      {protectedRoutes.map((route, idx) => (
        <Route
          key={route.path + idx}
          path={route.path}
          element={
            isAuthenticated ? (
              <UserLayout>{route.element}</UserLayout>
            ) : authInitialLoading ? (
              <PageLoading />
            ) : (
              // <Navigate
              //   to={{
              //     pathname: "/login",
              //     ...(getAllSearch()["code"] && {
              //       search: `?code=${getAllSearch()["code"]}${
              //         id ? `&state=id:${id},page:image_details` : ""
              //       }`,
              //     }),
              //   }}
              // />
              // <Navigate
              //   to={{
              //     pathname: "/login",
              //     ...(getAllSearch()["code"] && {
              //       search: `?code=${getAllSearch()["code"]}${
              //         id ? `&state=id:${id},page:image-details` : ""
              //       }`,
              //     }),
              //   }}
              // />
              <Navigate
                to={{
                  pathname: "/login",
                  search: `${
                    getAllSearch()["code"]
                      ? `?code=${getAllSearch()["code"]}${
                          id ? `&state=id:${id},page:image-details` : ""
                        }`
                      : id
                      ? `?state=id:${id},page:image-details`
                      : ""
                  }`,
                }}
              />

              // <Navigate
              //   to={{
              //     pathname: "/login",
              //     search: `${
              //       getAllSearch()["code"]
              //         ? `?code=${getAllSearch()["code"]}`
              //         : ""
              //     }${
              //       id
              //         ? `&state=id:${id},page:image-details`
              //         : ""
              //     }`,
              //   }}
              // />
            )
          }
        />
      ))}
    </Routes>
  );
};
