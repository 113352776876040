import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LOGIN_URL } from "../../constants/envs";

const MicoCover = ({ showLogin, urlParams }) => {
  const { t } = useTranslation();

  const getModifiedLoginUrl = () => {
    let modifiedUrl = LOGIN_URL;
    if (urlParams && urlParams.id && urlParams.page) {
      // modifiedUrl = modifiedUrl.replace("search", urlParams.page);
      modifiedUrl += `&state=id:${urlParams.id},page:${urlParams.page}`;
    }
    return modifiedUrl;
  };

  const modifiedLoginUrl = getModifiedLoginUrl();

  return (
    <div>
      <div className="title" style={{ fontSize: "9vw" }}>
        MICO{" "}
      </div>
      <div className="title" style={{ fontSize: "7vw" }}>
        VISION
      </div>
      <div>
        <div className="d-flex flex-row justify-content-start align-items-center">
          <span className="title" style={{ fontSize: "6.8vw" }}>
            AI
          </span>
          <div className="sidetext">
            <span className="subtext">New Creatives in Minutes with</span>
            <br />
            <span className="mico-subtext">MICO VISION AI</span>
            <br />
            {showLogin && (
              <span>
                <Link className="login-btn" to={modifiedLoginUrl}>
                  {t("login")}
                </Link>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MicoCover;
