import React, { createContext, useContext, useState } from "react";

const UploadContext = createContext();

export const useUploadContext = () => {
  return useContext(UploadContext);
};

export const UploadProvider = ({ children }) => {
  const [hasNavigated, setHasNavigated] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <UploadContext.Provider
      value={{
        hasNavigated,
        setHasNavigated,
        imageList,
        setImageList,
        progress,
        setProgress,
        currentStep,
        setCurrentStep,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};
