import React, { createContext, useContext, useEffect, useState } from "react";
import { SOCKET_URL } from "../constants/envs";

const WebSocketContext = createContext();

export const useSocketContext = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    // const newConnection = new WebSocket(SOCKET_URL);
    // newConnection.onopen = () => {
    //   console.log("WebSocket connection established");
    //   setConnection(newConnection);
    // };

    // newConnection.onclose = (error) => {
    //   setConnection("");
    //   console.error("Connection Closed!",error);
    // };

    return () => {
      // Close the WebSocket connection when component unmounts
      // if (connection) {
      //   connection.close();
      // }
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ connection, setConnection }}>
      {children}
    </WebSocketContext.Provider>
  );
};
