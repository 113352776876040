import React, { useState } from "react";
import MicoBackground from "../../components/MicoBackground";
import { useFilterContext } from "../../context/useFilterContext";
import CategoryFilter from "../../helpers/CategoryFilter";
import "../Upload/upload.scss";
import { useTranslation } from "react-i18next";
import upload from "../../assets/icons/uploaddisabled.svg";

const NewUpload = () => {
  const { filterTags } = useFilterContext();
  const [formValue, setFormValue] = useState({});
  const { t } = useTranslation();

  return (
    <section className={`d-flex gap-2 search-hero-section`}>
      <div className="w-100 overflow-auto position-relative">
        <MicoBackground />
        <div className="filter-container">
          <div className="container mt-3 mt-sm-0">
            <div className="d-flex justify-content-center">
              <div className="align-items-center flex-column w-100 max-w-900">
                <div className="d-flex justify-content-start align-items-start">
                  <div className="radio-container mt-4">
                    <label className="upload-text">
                      <input
                        type="radio"
                        name="option"
                        value="public"
                        checked
                      />
                      {t("public")}
                    </label>
                    <label className="upload-text">
                      <input type="radio" name="option" value="private" />
                      {t("private")}
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <span className="category-text">{t("selectCategory")}</span>
                </div>
                <div
                  className={`d-flex p-menuitem-link flex-wrap gap-2 justify-content-center w-100 align-items-center py-4`}
                >
                  <CategoryFilter
                    filterTags={filterTags}
                    formValue={formValue}
                    setFormValue={setFormValue}
                  />
                </div>
                <div className="upload-container rounded border-grey bg-white text-center gap-1">
                  <img src={upload} alt="uploadimg" className="m-5 mb-2" />
                  <span className="text-gray" style={{ fontSize: "1.35rem" }}>
                    {t("dndif")}
                  </span>
                  <span
                    className="text-gray m-1"
                    style={{ fontSize: "0.95rem" }}
                  >
                    ※{t("uploadInfo")}
                  </span>
                  <div className="d-flex justify-content-center align-items-center gap-2 my-3 mb-4">
                    <span className="text-gray" style={{ fontSize: "14px" }}>
                      {t("or")}
                    </span>
                    <button
                      className="mb-0 px-3 py-1 text-primary filter-tagss "
                      title={t("selectFiles")}
                      type="button"
                      style={{ fontSize: "14px" }}
                    >
                      {t("selectFiles")}
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center gap-1 m-1">
                  <input type="checkbox" />
                  <span className="text-gray fs-14">{t("termsOfUse")}</span>
                </div>
                <div className="upload-container">
                  <button className="w-100 max-w-200 rounded-pill border-0 p-2 my-3  btn">
                    {t("upload")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewUpload;
