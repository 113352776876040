import React from "react";

const TextInputWithCounter = ({
  maxLength = 70,
  value,
  handleChange,
  descriptionLength,
  name,
  placeholder=""
}) => {
  return (
    <div className="relative w-full max-w-md">
      <textarea
        className="w-full p-2 border rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 bg-transparent rounded-20 w-100 ps-3 pt-2 fs-16"
        rows={4}
        name={name||"edit_description"}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {/* <div className="absolute bottom-2 right-2 text-sm text-gray-500">
        {descriptionLength}/{maxLength}
      </div> */}
    </div>
  );
};

export default TextInputWithCounter;
