export const getCategoryLabel = (type, value, filters) => {
  if (value != undefined && value != null) {
    const filter = filters?.categories?.find((a) => a.category_key == type);
    if (filter) {
      const options = filter.category_options;
      const matchingKey = Object.keys(options).find(
        (key) => options[key] == value
      );
      return matchingKey;
    }
  }
  return "None";
};

export const getModelLabel = (type, value, filters) => {
  if (value != undefined && value != null) {
    const model = filters?.models.find((a) => a.model_key_name == type);
    if (model) {
      const options = model.model_options;
      const matchingKey = Object.keys(options).find(
        (key) => options[key] == value
      );
      return matchingKey;
    }
  }
  return "None";
};
