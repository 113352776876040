import React, { useEffect, useState } from "react";
import downArrow from "../assets/icons/arrow-down.svg";
import { useTranslation } from "react-i18next";
import { handleModelCheckBoxChange } from "./search";

function ModelOptions({ tag, formValue, setFormValue }) {
  const [showAll, setShowAll] = useState(false);
  const [disableOtherOptions, setDisableOtherOptions] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState({});
  const [visibleOptionsLength, setVisibleOptionsLength] = useState(4);

  const { t } = useTranslation();
  const options = Object.entries(tag.model_options);

  const toggleShowAll = () => setShowAll(!showAll);

  const handleChange = (e, value, modelId, tag, option) => {
    const isChecked = e.target.checked;
    setCheckedOptions((prev) => {
      const newCheckedOptions = { ...prev, [option]: isChecked };
      return newCheckedOptions;
    });

    handleModelCheckBoxChange(e, value, modelId, tag, formValue, setFormValue, isChecked);
  };

  const onAllToggle = async (e, modelId) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const allCheckedOptions = {};
      options.forEach((value) => {
        allCheckedOptions[value[0]] = true;
      });
      setCheckedOptions(allCheckedOptions);
      handleModelCheckBoxChange(e, -2, modelId, tag, formValue, setFormValue, e.target.checked);
      setDisableOtherOptions(true);
    } else {
      const allCheckedOptions = {};
      options.forEach((value) => {
        allCheckedOptions[value[0]] = false;
      });
      setCheckedOptions(allCheckedOptions);

      handleModelCheckBoxChange(e, -2, modelId, tag, formValue, setFormValue, e.target.checked);
      setDisableOtherOptions(false);
    }
  };

  useEffect(() => {
    if (formValue?.model_filters.length > 0) {
      setCheckedOptions([]);
      formValue.model_filters.forEach((model) => {
        if (model.model_key_name === tag.model_key_name) {
          const Tag = options
            ?.filter((m) => model?.model_options?.includes(m[1]))
            ?.map((a) => a[0]);
          if (Tag.length > 0) {
            Tag.forEach((option) => {
              setCheckedOptions((prev) => {
                const newCheckedOptions = { ...prev, [option]: true };
                return newCheckedOptions;
              });
            });
          }
        }
      });
    } else {
      setCheckedOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue]);

  useEffect(() => {
    const checkedOptionsLength = Object.keys(checkedOptions).length;
    if (checkedOptionsLength > 4) {
      setVisibleOptionsLength(checkedOptionsLength);
    } else {
      setVisibleOptionsLength(4);
    }
  }, [checkedOptions]);

  const sortedOptions = options.sort(([optionA], [optionB]) => {
    const checkedA = !!checkedOptions[optionA];
    const checkedB = !!checkedOptions[optionB];
    return checkedB - checkedA;
  });

  const limitedOptions = sortedOptions.slice(0, visibleOptionsLength);
  const remainingOptionsCount = sortedOptions.length - visibleOptionsLength;

  return (
    <ul className="model-options-list">
      {(showAll ? sortedOptions : limitedOptions).map(
        ([option, value], optionIndex) => (
          <li key={option} className="p-1">
            <input
              type="checkbox"
              name={tag.model_key_name}
              id={`option_${option}_${optionIndex}_${tag.model_key_name}`}
              onChange={(e) =>
                handleChange(e, value, tag.model_id, tag, option)
              }
              checked={!!checkedOptions[option]}
            // disabled={checkedOptions['All'] !== undefined && checkedOptions['All'] === true && value !== -2}
            />
            <label
              htmlFor={`option_${option}_${optionIndex}_${tag.model_key_name}`}
              style={{
                cursor: "pointer",
              }}
            >
              {t(option)}
            </label>
          </li>
        )
      )
      }
      {/* {
        sortedOptions?.length >= 1 &&
        (showAll || sortedOptions.length <= visibleOptionsLength) && (
          <>
            <li key={-2} className="p-1">
              <input
                type="checkbox"
                name={tag?.model_key_name}
                id={`option_none_-2_${tag?.model_key_name}`}
                onChange={(e) => onAllToggle(e, tag?.model_id)}
              />
              <label
                htmlFor={`option_none_-2_${tag?.model_key_name}`}
                style={{
                  cursor: "pointer",
                }}
              >
                {t("All")}
              </label>
            </li>

          </>
        )
      } */}
      {
        remainingOptionsCount > 0 && !showAll && (
          <li
            className="show-more"
            onClick={toggleShowAll}
            style={{ cursor: "pointer" }}
          >
            <span>
              {t("showMore")}({remainingOptionsCount})
            </span>
            <img
              onClick={() => setShowAll(!showAll)}
              src={downArrow}
              className={`bi bi-chevron-down`}
              style={{
                cursor: "pointer",
                transform: showAll ? "rotate(180deg)" : "rotate(0)",
              }}
              alt="icon"
            />
          </li>
        )
      }
      {
        (showAll && !checkedOptions['All']) && (
          <li
            className="show-more"
            onClick={toggleShowAll}
            style={{ cursor: "pointer" }}
          >
            <span>{t("showLess")}</span>
            <img
              onClick={() => setShowAll(!showAll)}
              src={downArrow}
              className={`bi bi-chevron-down`}
              style={{
                cursor: "pointer",
                transform: showAll ? "rotate(180deg)" : "rotate(0)",
              }}
              alt="icon"
            />
          </li>
        )
      }
    </ul >
  );
}

export default ModelOptions;
