import React from "react";

const PageLoading = ({ message }) => {
  return (
    <div className="loading" style={{ height: "calc(100vh - 83px)" }}>
      <div className="spinner-grow" role="status"></div>
      <span className="mt-3">{message || "Authorizing.."}</span>
    </div>
  );
};

export default PageLoading;
