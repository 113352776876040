import React from "react";
import { useNavigate } from "react-router-dom";
import search from "../../assets/icons/mainsearch.svg";
import upload from "../../assets/icons/upload.svg";
import "./dashboard.scss";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../context/useAuthContext";
import { DashCardLoader } from "../../components/Loaders/DashCardLoader";
import { Skeleton } from "@mui/material";
import MicoCover from "../../components/MicoCover";

const Dashboardd = () => {
  const navigate = useNavigate();
  const { permissions, initialLoading } = useAuthContext();
  const { t } = useTranslation();

  return (
    <div className="homePageBg height-auto">
      {/* {initialLoading ? (
        <div className="loading d-flex justify-content-center">
          <div className="spinner-grow" role="status"></div>
          <span className="mt-3">Loading...</span>
        </div>
      ) : ( */}
      {/* // <Skeleton width={window.innerWidth} height={window.innerHeight} />
        // <FullPageLoader/> */}
      <div className="row h-100">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <MicoCover showLogin={false} />

          <div className="d-flex flex-row  flex-md-nowrap flex-wrap justify-content-center gap-5">
            {initialLoading ? (
              <DashCardLoader />
            ) : (
              <div
                className="min-w-300 dashboard-card"
                onClick={() => navigate("/search")}
              >
                <span className="dashboard-title my-3">{t("search")}</span>
                <img src={search} alt="searchimg" />
                <button className={`rounded-pill border-0 w-100 p-2 my-3 btn`}>
                  {t("searchImages")}
                </button>
              </div>
            )}

            {/* {initialLoading ? (
              <DashCardLoader />
            ) : (
              // <Skeleton
              //   variant="rounded"
              //   className="mt-5"
              //   width={250}
              //   height={250}
              //   animation="wave"
              // />
              <div
                className="min-w-300 dashboard-card"
                onClick={() => navigate("/create")}
              >
                <span className="dashboard-title my-3">{t("create")}</span>
                <img src={search} alt="searchimg" />
                <button className={`rounded-pill border-0 w-100 p-2 my-3 btn`}>
                  {t("create")}
                </button>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboardd;
