import axios from "axios";
import { getCookie } from "cookies-next";
import { toast } from "react-toastify";
import { authSessionKey } from "../context/useAuthContext";

const axiosInstance = axios.create();

// Add axios interceptor for handling errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      console.log("Unexpected API error:", error);
      toast.error(
        "Unexpected error while communicating with the server. Please try again later."
      );
    } else {
      toast.error(
        error?.response?.data?.detail?.status_meassages ||
          error?.response?.data?.message ||
          error?.response?.data?.data?.message ||
          error?.message ||
          "Error while communicating with the server."
      );
    }
    return Promise.reject(error);
  }
);

// Add axios interceptor for setting Authorization header
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getCookie(authSessionKey);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Function to make a POST request
const post = async (url, data) => {
  try {
    const response = await axiosInstance.post(
      // TODO, remove / from between and add it to all endpoints
      `${process.env.REACT_APP_BASE_URL}/${url}`,
      data
    );
    return response?.data || [];
  } catch (error) {
    return new Error(error?.response?.data);
  }
};
const imagePost = async (url, data) => {
  try {
    const response = await axiosInstance.post(
      // TODO, remove / from between and add it to all endpoints
      `${process.env.REACT_APP_BASE_URL}/${url}`,
      data,
      { responseType: "blob" }
    );
    return response?.data || [];
  } catch (error) {
    return new Error(error?.response?.data);
  }
};
const imageGet = async (url, data) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/${url}?image_url=${encodeURI(
        data.image_url
      )}`,
      {
        responseType: "blob",
      }
    );
    return response.data ?? [];
  } catch (error) {
    return new Error(error?.response?.data);
  }
};
// Function to make a GET request
const get = async (url) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/${url}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export { post, get, imageGet, imagePost };
