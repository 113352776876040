import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import "./download.scss";
import { imageGet, post } from "../../API/axios";
import { useSearchContext } from "../../context/useSearchContext";
import MicoBackground from "../../components/MicoBackground";
// import { TruncateDescription } from "../../helpers/TruncateDescription";
import DownloadContent from "./main";
import CardLoader from "../../components/Loaders/CardLoader";
import { getAllSearch } from "../../helpers/url";
import { toast } from "react-toastify";
import Card from "../../components/Card";
import { useAuthContext } from "../../context/useAuthContext";

const ImageDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = getAllSearch();

  let { data, swiperImages, fetchNewImageDetail } = location.state || {};
  const [slidesPerView, setSlidesPerView] = useState(5);
  const [callSimilarAPI, setCallSimilarAPI] = useState(true);
  const [visible, setVisible] = useState(false);
  const { isDebug, setIsDebug } = useAuthContext();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getImageDetails(id);
    }
    if (fetchNewImageDetail && !id) {
      getImageDetails(id);
    }
    if (!data && !id) {
      navigate("/search");
    }
  }, [data, navigate, id]);

  const { i18n } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const currentLanguage = i18n.language;
  const { setIsEdited } = useSearchContext();
  const [similarImages, setSimilarImages] = useState([]);

  const [formValue, setFormValue] = useState({
    image_id: data?.image_id || id,
    image_uuid: data?.image_uuid || "",
    lang: currentLanguage,
    edit_description:
      currentLanguage === "ja"
        ? data?.image_description_ja
        : data?.image_description,
    // edit_tags:
    //   currentLanguage === "ja" ? data?.image_ja_tags : data?.image_en_tags,
    edit_tags: [...(data?.image_en_tags || []), ...(data?.image_ja_tags || []),],
    inputTag: "",
    image_url: data?.image_url || "",
    ctr: data?.ctr || [],
    score: data?.score,
    model_filters: data?.model_filters,
    image_size: data?.image_size,
    image_extension: data?.image_extension,
    text_extraction: data?.text_extraction,
    industry: data?.industry,
    delivery_type: data?.delivery_type,
    delivery_content: data?.delivery_content,
    image_type: data?.image_type,
  });
  const [popupImage, setPopUpImage] = useState({});
  const [popUpImages, setPopUpImages] = useState({});
  const [isPopUpLoading, setIsPopUpLoading] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let [dataa, setDataa] = useState(data);

  const onLangChange = (lang) => {
    setFormValue((prevFormValue) => ({
      ...prevFormValue,
      lang: lang,
      edit_tags: [...(data?.image_en_tags || []), ...(data?.image_ja_tags || []),],
      edit_description:
        currentLanguage === "ja"
          ? data?.image_description_ja
          : data?.image_description,
    }));
  };

  useEffect(() => {
    onLangChange(currentLanguage);
  }, [currentLanguage]);

  const getImageDetails = async (imageId) => {
    setIsLoading(true);
    if (!imageId) {
      setCallSimilarAPI(true);
    }
    setPopUpImages([]);

    const { image, status_code } = await post("get_image_detail/", {
      image_id: imageId ? imageId : formValue.image_id,
    });
    if (status_code === 204) {
    }
    setDataa(image);

    setFormValue({
      image_id: image?.image_id || 0,
      image_uuid: image?.image_uuid || "",
      lang: currentLanguage,
      edit_description:
        image[
        currentLanguage === "ja"
          ? "image_description_ja"
          : "image_description"
        ] || "",
      // edit_tags: image["image_" + (currentLanguage || "en") + "_tags"] || [],
      edit_tags:[ ...(image["image_en_tags"] || []), ...(image["image_ja_tags"] || [])],
      inputTag: "",
      image_url: image?.image_url || "",
      ctr: image?.ctr || [],
      score: image?.score,
      model_filters: image?.model_filters,
      image_size: image?.image_size,
      image_extension: image?.image_extension,
      industry: image?.industry,
      text_extraction: image?.text_extraction,
      delivery_type: image?.delivery_type,
      delivery_content: image?.delivery_content,
      image_type: image?.image_type,
    });
    if (imageId) {
      setSimilarImages(image?.similar_list ? image?.similar_list : []);
    }
    setIsEdited(false);
    setIsLoading(false);
  };

  const handleViewDetails = async (data) => {
    setVisible(false);
    getImageDetails(data?.image_id);
    navigate(`/image-details?id=${popupImage.image_id}`, {
      state: {
        data: data,
      },
    });
    setFormValue(popupImage);
    setSimilarImages(popUpImages);
  };

  const handleDownload = async (url) => {
    try {
      setIsSaving(true);
      const response = await imageGet("download_image", { image_url: url });
      const downloadUrl = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = url.substring(url.lastIndexOf("/") + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
      console.log("Image downloaded successfully.");
      setIsSaving(false);
      setIsEdited(false);
    } catch (error) {
      setIsSaving(false);
      setIsEdited(false);
      console.error("Error downloading image:", error);
    }
  };

  const getSimilarImages = async () => {
    setIsLoading(true);
    await post("get_similar_images/", { image_id: formValue?.image_id }).then(
      (res) => {
        setSimilarImages(res?.similar_image_list);
      }
    );
    // setCallSimilarAPI(false);
    setIsLoading(false);
  };

  useEffect(() => {
    // if (callSimilarAPI) {
    getSimilarImages();
    // }
  }, [formValue.image_id]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 450) {
        setSlidesPerView(1);
      } else if (window.innerWidth <= 700) {
        setSlidesPerView(2);
      } else if (window.innerWidth <= 992) {
        setSlidesPerView(3);
      } else if (window.innerWidth <= 1200) {
        setSlidesPerView(4);
      } else if (window.innerWidth <= 1400) {
        setSlidesPerView(5);
      } else if (window.innerWidth <= 1920) {
        setSlidesPerView(6);
      } else {
        setSlidesPerView(7);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigateToEdit = async () => {
    const editData = formValue == popupImage ? dataa : data;

    navigate("/new_edit", {
      state: { swiperImages, data: (dataa || editData) },
    });
  };

  const onSlideClick = async (img) => {
    setIsPopUpLoading(true);
    const image = similarImages.find(
      (a) => a.similar_image_id === img?.similar_image_id
    );

    if (image) {
      setVisible(true);
      setPopUpImage({
        ctr: image?.image_ctr || [],
        image_id: image?.similar_image_id || 0,
        image_description_ja: image?.image_desc_ja,
        image_description: image?.image_desc_en,
        image_url: image?.image_url || "",
        image_en_tags: image?.image_tags_en,
        image_ja_tags: image?.image_tags_ja,
        score: image?.similar_score,
        image_type: image?.image_type,
        site_id: image.site_id,
      });
      setIsPopUpLoading(false);
    } else {
      setVisible(false);
      setIsPopUpLoading(false);
      toast.error("Image Not Found!");
    }
  };

  return (
    <div>
      <section className="d-flex gap-2 search-hero-section overflow-hidden">
        <div className="w-100">
          <>
            <MicoBackground />
            <div className="d-flex justify-content-center w-100">
              <div
                className="max-w-1260 w-100 bg-white box-shadow p-5"
                style={{ position: "absolute", top: "170px" }}
              >
                <DownloadContent
                  formValue={formValue}
                  isLoading={isLoading}
                  similarImages={similarImages}
                  getImageDetails={getImageDetails}
                  slidesPerView={slidesPerView}
                  isPopUp={false}
                  isSaving={isSaving}
                  handleDownload={handleDownload}
                  navigateToEdit={navigateToEdit}
                  onSlideClick={onSlideClick}
                />
                {visible && (
                  <Dialog
                    visible={visible}
                    draggable={false}
                    onHide={() => {
                      if (!visible) return;
                      setVisible(false);
                    }}
                    style={{ width: "auto" }}
                  >
                    {isPopUpLoading ? (
                      <CardLoader />
                    ) : (
                      <Card
                        data={popupImage}
                        isDebug={isDebug}
                        handleViewDetails={handleViewDetails}
                      />
                    )}
                  </Dialog>
                )}
              </div>
            </div>
          </>
        </div>
      </section>
    </div>
  );
};

export default ImageDetails;
