import { post } from "./axios";
import { toast } from "react-toastify";

export const SearchAPI = {
  async updateDescAndTags(payload) {
    const response = await post(`update_description_tags/`, {
      image_list: JSON.stringify(payload),
    });
    toast.success("Description and Tags Updated Successfully.");
    return response?.data;
  },
  async getSearchTags() {
    const response = await post(`get_all_unique_tags`, { lang: "all" });
    return response?.data;
  },
  async getSearchResults(payload) {
    const response = await post("text_search_image", payload);
    return response;
  },
};
