import { Skeleton } from "@mui/material";
import React from "react";

const SiteLoader = () => {
  return (
    <div className="col-md-4 col-12 py-2" style={{ cursor: "pointer" }}>
      <Skeleton
        animation="wave"
        variant="rounded"
        className="rounded-pill position-relative max-w-400 border-2 w-100 btn"
        height={30}
      />
    </div>
  );
};

const UserNameLoader = () => {
  return (
    <div
      className="rounded-pill fs-14 bg-transparent py-2 px-sm-3  min-w-170"
      style={{ cursor: "pointer" }}
    >
      <Skeleton
        animation="wave"
        variant="rounded"
        className="rounded-pill position-relative border-2 w-100 btn"
        height={30}
      />
    </div>
  );
};

export { SiteLoader, UserNameLoader };
