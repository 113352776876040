import { Skeleton, Box, useMediaQuery } from "@mui/material";
import React from "react";
import "../../views/Search/search.scss";

const DetailsLoader = () => {
  // Define custom breakpoints
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:960px)");

  const getWidth = () => {
    if (isSmallScreen) return "100%";
    if (isMediumScreen) return "90%";
    return "70%"; // Adjust this for larger screens
  };

  return (
    <Box
      className="w-100"
      width={getWidth()}
      margin="auto"
      padding={isSmallScreen ? 1 : 2}
    >
      <Box
        display="flex"
        className="my-4"
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
        gap={1}
      >
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
        <Skeleton animation="wave" variant="rounded" width={160} height={30} />
      </Box>
      <Box
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        gap={2}
      >
        <Skeleton variant="rounded" animation="wave" height={400} width={300} />

        <Box width="100%">
          <Skeleton animation="wave" variant="text" height={80} width="100%" />
          {[...Array(8)].map((_, idx) => (
            <Box
              key={idx}
              display="flex"
              flexDirection={isSmallScreen ? "column" : "row"}
              justifyContent={isSmallScreen ? "start" : "space-between"}
              gap={1}
              mb={1}
              alignItems={isSmallScreen ? "start" : "center"}
            >
              <Skeleton
                animation="wave"
                variant="rounded"
                width={isSmallScreen ? "80%" : "25%"}
                height={27}
                mb={isSmallScreen ? 1 : 0}
              />
              {[...Array(4)].map((_, i) => (
                <Skeleton
                  key={i}
                  animation="wave"
                  variant="rounded"
                  width={isSmallScreen ? "40%" : "16%"}
                  height={27}
                  mb={isSmallScreen ? 1 : 0}
                />
              ))}
            </Box>
          ))}

          <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            justifyContent={isSmallScreen ? "start" : "space-between"}
            gap={1}
            mt={3}
            alignItems={isSmallScreen ? "start" : "center"}
          >
            {[...Array(8)].map((_, i) => (
              <Skeleton
                key={i}
                animation="wave"
                variant="rounded"
                width={isSmallScreen ? "20%" : "16%"}
                height={27}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box className="d-flex p-1 my-2 gap-4" width="100%">
        <Skeleton
          animation="wave"
          height={50}
          variant="rounded"
          className={`rounded-pill border-0 w-100 p-2 my-3 btn`}
        />
        <Skeleton
          animation="wave"
          height={50}
          variant="rounded"
          className={`rounded-pill border-0 w-100 p-2 my-3 btn`}
        />
      </Box>
    </Box>
  );
};

export default DetailsLoader;
