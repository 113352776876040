import React, { createContext, useContext } from "react";
import { get } from "../API/axios";
import { useTranslation } from "react-i18next";

const TranslationContext = createContext();

export const useTranslationContext = () => {
  return useContext(TranslationContext);
};

export const enTranslationKey = "_MICO_EN_TRANSLATIONS_KEY_";
export const jaTranslationKey = "_MICO_JA_TRANSLATIONS_KEY_";

export const TranslationProvider = ({ children }) => {
  const { i18n } = useTranslation();

  const fetchTranslations = async () => {
    return new Promise((resolve, rej) => {
      try {
        Promise.all([get("i18n/en"), get("i18n/ja")])
          .then((LangData) => {
            i18n.addResourceBundle(
              "en",
              "translation",
              LangData[0],
              true,
              true
            );
            i18n.addResourceBundle(
              "ja",
              "translation",
              LangData[1],
              true,
              true
            );
            localStorage.setItem(enTranslationKey, JSON.stringify(LangData[0]));
            localStorage.setItem(jaTranslationKey, JSON.stringify(LangData[1]));
            return resolve(true);
          })
          .catch((error) => {
            console.error("Failed to fetch translations:", error);
          });
      } catch (error) {
        console.error("Failed to fetch translations:", error);
        return rej(error);
      }
    });
  };

  const saveTranslations = async () => {
    const enTranslation = await JSON.parse(
      localStorage.getItem(enTranslationKey)
    );
    const jaTranslation = await JSON.parse(
      localStorage.getItem(jaTranslationKey)
    );
    if (enTranslation && jaTranslation) {
      i18n.addResourceBundle("en", "translation", enTranslation, true, true);
      i18n.addResourceBundle("ja", "translation", jaTranslation, true, true);
      return;
    } else {
      fetchTranslations();
    }
  };

  return (
    <TranslationContext.Provider
      value={{ fetchTranslations, saveTranslations }}
    >
      {children}
    </TranslationContext.Provider>
  );
};
