import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../context/useAuthContext";
import downArrow from "../../assets/icons/drop_blue.svg";
import "../Settings/setting.scss";
import { CircularProgress } from "@mui/material";
import MicoBackground from "../../components/MicoBackground";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const Create = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const { t } = useTranslation();
  const { permissions, selectedSite } = useAuthContext();
  const [filteredSites, setFilteredSites] = useState(permissions?.access_sites);
  const inputRef = useRef(null);
  const inputTypeRef = useRef(null);
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [typeOptions, setTypeOptions] = useState([
    "Create by Uploading Image",
    "Create in Canvas",
    "Create by Search",
  ]);
  const [type, setType] = useState("Create by Uploading Image");

  const [formValue, setFormValue] = useState({
    site: "",
    title: "",
  });

  useEffect(() => {
    setFilteredSites(permissions?.access_sites);

    let allSite = { site_id: 0, site_name: "All" };
    const allSites = permissions?.main_site_id == 0 ? true : false;
    const siteData = allSites
      ? allSite
      : permissions?.access_sites.find(
          (s) => s.site_id == permissions?.main_site_id
        );
    setFormValue({
      ...formValue,
      site: siteData ? siteData : permissions?.access_sites[0],
    });
  }, [permissions]);

  const onSiteChange = (site = "") => {
    setIsDropDownOpen(false);
    setFormValue({ ...formValue, site });
    setFilteredSites(permissions?.access_sites);
  };

  const handleSiteChange = (e) => {
    const value = e.target.value;
    const filtered = permissions?.access_sites.filter((site) =>
      site.site_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSites(filtered);
  };

  const handleTypeSelection = (type) => {
    setType(type);
    setIsTypeOpen(false);
  };

  const onTypeTextChange = (e) => {
    const value = e.target.value;
    setType(value); // Update the type state with the input value
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsDropDownOpen(false);
      }
    };

    const handleClickOutsideType = (event) => {
      if (
        inputTypeRef.current &&
        !inputTypeRef.current.contains(event.target)
      ) {
        setIsTypeOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutsideType);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleClickOutsideType);
    };
  }, [isDropDownOpen, isTypeOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const onNextClick = () => {
    if (formValue.site === "" || formValue.title == "") {
      toast.info("Please fill all the details");
    } else {
      navigate("/create-template", {
        state: {
          data,
        },
      });
    }
  };

  return (
    <div className="d-flex justify-content-center w-100 position-relative">
      <MicoBackground />
      <div
        className="font-sora w-100"
        style={{ position: "absolute", top: "140px" }}
      >
        <div className="d-flex justify-content-center px-3">
          <div
            className="bg-white py-sm-3 px-sm-4 p-3 w-100 setting shadow"
            style={{ maxWidth: "600px" }}
          >
            <h2 className="text-center fs-30">{t("create")}</h2>
            <div className="border-bottom pb-4">
              <div className="d-flex justify-content-between gap-2 align-items-center">
                <label className="text-extrabluedark text-nowrap me-sm-4 fs-16 min-w-100">
                  {t("tenant")}
                </label>
                <div
                  className="dropdown w-100 min-h-36"
                  style={{ maxWidth: "344px" }}
                >
                  {selectedSite?.site_name && (
                    <div
                      className="w-100 position-relative max-w-400 min-h-36"
                      ref={inputRef}
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="border rounded-pill d-flex gap-2 border-blue align-items-center justify-content-between min-h-36 min-w-127 p-1 ps-2 pe-md-3"
                        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                      >
                        {isDropDownOpen ? (
                          <input
                            type="text"
                            className="remove_outline w-100"
                            onChange={handleSiteChange}
                            autoFocus
                          ></input>
                        ) : (
                          <span className="text-500 fs-14 w-100 pl-2">
                            {formValue?.site?.site_name}
                          </span>
                        )}
                        <img
                          onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                          src={downArrow}
                          className={`bi bi-chevron-down`}
                          style={{
                            cursor: "pointer",
                            transform: isDropDownOpen
                              ? "rotate(180deg)"
                              : "rotate(0)",
                          }}
                          alt="icon"
                        />
                      </div>
                      {isDropDownOpen && (
                        <>
                          <ul className="position-absolute list-unstyled sitemenu w-100">
                            {permissions && (
                              <li
                                onClick={() =>
                                  onSiteChange({
                                    site_id: -1,
                                    site_name: "All",
                                  })
                                }
                              >
                                <div
                                  className="sitemenu-item text-center fs-14"
                                  href="#"
                                >
                                  All
                                </div>
                              </li>
                            )}
                            {filteredSites &&
                              filteredSites?.map((site, index) => (
                                <li
                                  key={index}
                                  onClick={() => onSiteChange(site)}
                                >
                                  <div
                                    className="sitemenu-item text-center fs-14"
                                    href="#"
                                  >
                                    {site.site_name}
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="d-flex justify-content-between gap-2 mt-3 align-items-center">
                <label className="text-extrabluedark text-nowrap me-sm-4 fs-16 min-w-100">
                  {t("type")}
                </label>
                <div
                  className="dropdown w-100 min-h-36"
                  style={{ maxWidth: "344px" }}
                >
                  <div
                    className="w-100 position-relative max-w-400 min-h-36"
                    ref={inputTypeRef}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      className="border rounded-pill d-flex gap-2 border-blue align-items-center justify-content-between min-h-36 min-w-127 p-1 ps-2 pe-md-3"
                      onClick={() => setIsTypeOpen(!isTypeOpen)}
                    >
                      {isTypeOpen ? (
                        <input
                          type="text"
                          className="remove_outline w-100"
                          autoFocus
                          onChange={onTypeTextChange}
                        ></input>
                      ) : (
                        <span className="text-500 fs-14 w-100 pl-2">
                          {type}
                        </span>
                      )}
                      <img
                        onClick={() => setIsTypeOpen(!isTypeOpen)}
                        src={downArrow}
                        className={`bi bi-chevron-down`}
                        style={{
                          cursor: "pointer",
                          transform: isTypeOpen
                            ? "rotate(180deg)"
                            : "rotate(0)",
                        }}
                        alt="icon"
                      />
                    </div>
                    {isTypeOpen && (
                      <>
                        <ul className="position-absolute list-unstyled sitemenu w-100">
                          {typeOptions.map((option, index) => (
                            <li
                              onClick={() => handleTypeSelection(option)}
                              key={index}
                            >
                              <div
                                className="sitemenu-item text-center fs-14"
                                href="#"
                              >
                                {option}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div> */}
              <div className="d-flex justify-content-between gap-2 mt-3 align-items-center">
                <label className="text-extrabluedark text-nowrap me-sm-4 fs-16 min-w-100">
                  {t("title")}
                </label>
                <div
                  className="dropdown w-100 min-h-36"
                  style={{ maxWidth: "344px" }}
                >
                  <div className="w-100 position-relative max-w-400 min-h-36">
                    <div
                      style={{ cursor: "pointer" }}
                      className="border rounded-pill d-flex gap-2 border-blue align-items-center justify-content-between min-h-36 min-w-127 p-1 ps-2 pe-md-3"
                    >
                      <input
                        type="text"
                        className="remove_outline w-100"
                        autoFocus
                        name="title"
                        onChange={handleChange}
                      ></input>

                      <span className="text-500 fs-14 w-100 pl-2">
                        {/* {type} Display the selected type */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-4 gap-5 d-flex justify-content-end align-items-center w-100">
              <div className="w-100" style={{ maxWidth: "344px" }}>
                <button
                  className="w-100 rounded-pill border-0 detailsbtn py-2 text-white fs-16"
                  disabled={isSaving}
                  onClick={onNextClick}
                >
                  {!isSaving ? (
                    t("next")
                  ) : (
                    <CircularProgress color="inherit" size={20} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
