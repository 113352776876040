import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { imageGet } from "../../API/axios";
import "../ImageDetails/download.scss";
import MicoBackground from "../../components/MicoBackground";
import * as markerjs2 from "markerjs2";
import { Skeleton } from "@mui/material";

const CreateTemplate = () => {
  const location = useLocation();
  let { data } = location.state || {};
  const imgRef = useRef(null);
  const { t } = useTranslation();
  const [imageUrl, setImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data?.image_url) {
      getImageDetails(data.image_url);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl]);

  const showMarkerArea = (older = false) => {
    if (imgRef.current !== null) {
      const markerArea = new markerjs2.MarkerArea(imgRef.current);
      markerArea.availableMarkerTypes = markerArea.ALL_MARKER_TYPES;

      markerArea.addEventListener("render", (event) => {
        if (imgRef.current) {
          imgRef.current.src = event.dataUrl;
          localStorage.setItem("preState", JSON.stringify(event.state));
        }
      });

      if (older) {
        const preState = localStorage.getItem("preState");
        if (preState) {
          try {
            const state = JSON.parse(preState);
            markerArea.restoreState(state);
          } catch (error) {
            console.error("Error restoring state:", error);
          }
        }
      }

      markerArea.show();
    }
  };

  const getImageDetails = async (url) => {
    try {
      setIsLoading(true);
      const response = await imageGet("download_image/", { image_url: url });
      const urlObject = URL.createObjectURL(response);
      setImageURL(urlObject);
      setIsLoading(false);
    } catch (error) {
      console.error("Error downloading image:", error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <section className="d-flex gap-2 search-hero-section overflow-hidden">
        <div className="w-100">
          <MicoBackground />
          <div className="d-flex justify-content-center w-100">
            <div
              className="max-w-1260 w-100 h-100 bg-white box-shadow p-5"
              style={{ top: "170px" }}
            >
              {isLoading ? (
                <>
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    height={400}
                    // width={300}
                    className="w-100"
                  />
                  <Skeleton
                    animation="wave"
                    height={50}
                    variant="rounded"
                    className={`rounded-pill border-0  p-2 my-3 btn`}
                  />
                  <Skeleton
                    animation="wave"
                    height={50}
                    variant="rounded"
                    className={`rounded-pill border-0  p-2 my-3 btn`}
                  />
                </>
              ) : (
                <div className="d-flex mt-2 gap-4 flex-column flex-md-row">
                  <div className="max-w-312 max-h-498 h-100 w-100">
                    {imageUrl && (
                      <>
                        <img
                          ref={imgRef}
                          src={imageUrl}
                          alt=""
                          className="w-100"
                          onClick={() => showMarkerArea(true)}
                        />
                      </>
                    )}
                    <div className="p-3">
                      <button
                        className="rounded-pill border-0 p-2 max-w-170 w-100 position-relative text-dark bg-sky-blue"
                        onClick={() => {
                          showMarkerArea(true);
                        }}
                      >
                        Show Older
                      </button>
                    </div>
                    <div className="p-3">
                      <button
                        className="rounded-pill border-0 p-2 max-w-170 w-100 position-relative text-dark bg-sky-blue"
                        disabled={false}
                      >
                        {t("create")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateTemplate;
