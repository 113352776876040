import { lazy } from "react";
import { Navigate } from "react-router-dom";
import LoginNew from "../views/Login/login";
import Dashboardd from "../views/Dashboard/dashboard";
import Search from "../views/Search";
import ImageDetails from "../views/ImageDetails";
import NewEdit from "../views/Edit/edit";
import NewUpload from "../views/NewUpload";
import Create from "../views/Create";
import CreateTemplate from "../views/CreateTemplate";

const Edit = lazy(() => import("../views/Edit/index"));
const Upload = lazy(() => import("../views/Upload/index"));
const UploadEdit = lazy(() => import("../views/Upload/UploadEdit"));
const Settings = lazy(() => import("../views/Settings/index"));

export const protectedRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    element: <Dashboardd />,
  },
  {
    path: "/search",
    name: "search",
    element: <Search />,
  },
  {
    path: "/edit",
    name: "edit",
    element: <Edit />,
  },
  {
    path: "/upload",
    name: "upload",
    element: <Upload />,
  },
  {
    path: "/upload-edit",
    name: "upload-edit",
    element: <UploadEdit />,
  },
  {
    path: "/settings",
    name: "settings",
    element: <Settings />,
  },
  {
    path: "/image-details",
    name: "image-details",
    element: <ImageDetails />,
  },
  {
    path: "/new_edit",
    name: "new_edit",
    element: <NewEdit />,
  },
  {
    path: "/new_upload",
    name: "new_upload",
    element: <NewUpload />,
  },
  {
    path: "/create",
    name: "create",
    element: <Create />,
  },
  {
    path: "/create-template",
    name: "create-template",
    element: <CreateTemplate />,
  },
  // {
  //   path: "/new_search",
  //   name: "new-search",
  //   element: <NewSearch/>,
  // },
  // {
  //   path: "/new_dashboard",
  //   name: "new_dashboard",
  //   element: <Dashboardd />,
  // },
];

export const publicRoutes = [
  {
    path: "/",
    name: "root",
    element: <Navigate to="/dashboard" />,
  },
  {
    path: "/login",
    name: "login",
    element: <LoginNew />,
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   element: <Login />,
  // },
];
