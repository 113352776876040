import { Skeleton, Box, useMediaQuery } from "@mui/material";
import React from "react";
import "../../views/Search/search.scss";

const SearchLoader = () => {
  // Define custom breakpoints
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:960px)");

  const getWidth = () => {
    if (isSmallScreen) return "100%";
    if (isMediumScreen) return "90%";
    return "80%"; // Adjust this for larger screens
  };

  return (
    <Box
      className="max-w-700"
      width={getWidth()}
      margin="auto"
      padding={isSmallScreen ? 1 : 2}
    >
      <Skeleton
        animation="wave"
        height={40}
        variant="rounded"
        className={`rounded-pill border-0 w-100 p-2 my-3 btn`}
      />
      {[...Array(3)].map((_, idx) => (
        <Box
          key={idx}
          display="flex"
          className="my-3 m-1"
          flexDirection={isSmallScreen ? "column" : "row"}
          alignItems="center"
          justifyContent="center"
        >
          <Skeleton
            animation="wave"
            variant="rounded"
            width="30%"
            height={30}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width="100%"
            height={30}
            style={{
              marginLeft: isSmallScreen ? 0 : "8px",
              marginTop: isSmallScreen ? "8px" : 0,
            }}
          />
        </Box>
      ))}

      {[...Array(4)].map((_, idx) => (
        <Box
          key={idx}
          display="flex"
          justifyContent={isSmallScreen ? "center" : "space-between"}
          alignItems="center"
          flexWrap="wrap"
          className="my-3 m-1"
        >
          <Skeleton
            animation="wave"
            variant="rounded"
            width="25%"
            height={27}
          />
          {[...Array(4)].map((_, i) => (
            <Skeleton
              key={i}
              animation="wave"
              variant="rounded"
              width="16%"
              height={27}
              className="mx-1"
            />
          ))}
        </Box>
      ))}
      <Box
        display="flex"
        className="my-3 p-1"
        flexDirection={isSmallScreen ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
      >
        <Skeleton animation="wave" variant="rounded" width="20%" height={35} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="100%"
          height={35}
          style={{
            marginLeft: isSmallScreen ? 0 : "8px",
            marginTop: isSmallScreen ? "8px" : 0,
          }}
        />
      </Box>
      <Box className="p-1 py-2" width="100%">
        <Skeleton animation="wave" variant="rounded" height={35} width="100%" />
      </Box>
      <Box className="d-flex justify-content-center align-items-center">
        <Skeleton
          animation="wave"
          height={40}
          width="50%"
          variant="rounded"
          className={`rounded-pill border-0  p-2 my-3 btn`}
        />
      </Box>
    </Box>
  );
};

export default SearchLoader;
