import React, { createContext, useContext, useState, useEffect } from "react";

const EditContext = createContext();

export const useEditContext = () => {
  return useContext(EditContext);
};

export const EditProvider = ({ children }) => {
  const [editedImages, setEditedImages] = useState([]);
  const [savedImages, setSavedImages] = useState([]);
  const [searchQuery, setSearchQuery] = useState({});
  const [refreshSearch, setRefreshSearch] = useState(false);

  const saveEditedImages = (image_id) => {
    const newEditedImages = [...editedImages, image_id];
    localStorage.setItem("editedImages", JSON.stringify(newEditedImages));
    setEditedImages(newEditedImages);
  };

  const saveSavedImages = (image_id) => {
    const newSavedImages = [...savedImages, image_id];
    localStorage.setItem("savedImages", JSON.stringify(newSavedImages));
    setSavedImages(newSavedImages);
  };

  useEffect(() => {
    const storedEditedImages = JSON.parse(localStorage.getItem("editedImages"));
    if (storedEditedImages) {
      setEditedImages(storedEditedImages);
    }
    const storedSavedImages = JSON.parse(localStorage.getItem("savedImages"));
    if (storedSavedImages) {
      setSavedImages(storedSavedImages);
    }
  }, []);

  const clearEditData = () => {
    setSavedImages([]);
    setEditedImages([]);
    localStorage.removeItem("savedImages");
    localStorage.removeItem("editedImages");
  };

  return (
    <EditContext.Provider
      value={{
        editedImages,
        saveEditedImages,
        savedImages,
        saveSavedImages,
        clearEditData,
        searchQuery,
        setSearchQuery,
        setRefreshSearch,
        refreshSearch,
      }}
    >
      {children}
    </EditContext.Provider>
  );
};
