import React, { createContext, useContext, useEffect, useState } from "react";
import { post } from "../API/axios";
import { useAuthContext } from "./useAuthContext";

const FilterContext = createContext();

export const useFilterContext = () => {
  return useContext(FilterContext);
};
export const modelFiltersKey = "_MICO_MODEL_FILTER_KEY_";
export const categoriesFilterKey = "_MICO_CATEGORY_FILTER_KEY_";

export const FilterProvider = ({ children }) => {
  const [filterTags, setFilterTags] = useState();

  const fetchFilterTags = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await post(`get_filters/`);

        const updatedModels = data.filters.models.map((model) => {
          let updatedOptions = {};

          if (Object.keys(model.model_options).length >= 2) {
            updatedOptions = { ...model.model_options, None: -1, All: -2 };
          } else {
            updatedOptions = { ...model.model_options, None: -1 };
          }
      
          return { ...model, model_options: updatedOptions };
        });

        const updatedCategories = data.filters.categories.map((category) => {
          let updatedOptions = {};
          if (Object.keys(category.category_options).length >= 2) {
            updatedOptions = { ...category.category_options, None: -1, All: -2 };
          } else {
            updatedOptions = { ...category.category_options, None: -1 };
          }

          return { ...category, category_options: updatedOptions };
        });

        setFilterTags({ models: updatedModels, categories: updatedCategories });
        localStorage.setItem(modelFiltersKey, JSON.stringify(updatedModels));
        localStorage.setItem(
          categoriesFilterKey,
          JSON.stringify(updatedCategories)
        );

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getSearchTags = async () => {
    const response = await post(`get_all_unique_tags`, { lang: "all" });
    return response?.data;
  };

  return (
    <FilterContext.Provider
      value={{
        filterTags,
        fetchFilterTags,
        getSearchTags,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
